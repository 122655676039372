<template>
  <v-footer fixed class="gfoot">
    <v-row no-gutters align="end" class="pt-2 pb-1">
      <v-col class="text-center" v-for="(menu, i) in menus.menus" :key="i">
        <div v-if="menu.screen_id == 'sub_menu'" @click="submenu">
          <img :src="menu.icon_url" height="19"><br>
          <p class="gftext pt-0 ma-0" :class="(submenu_flg === true) ? 'maincolor' : ''">{{menu.title}}</p>
        </div>
        <div v-else>
          <img :src="menu.icon_url" height="19"><br>
          <p class="gftext pt-0 ma-0" >{{menu.title | gfTitle}}</p>
        </div>
      </v-col>
    </v-row>


    <div class="submenu" v-show="submenu_flg">
      <div class="px-3">
        <p class="pa-0 mb-2 title text-center">MENU</p>
        <v-chip x-small class="my-0" color="#fff" outlined>マイページ</v-chip>
      </div>
      <v-row align="center" class="px-3">
        <v-col cols="2">
          <v-icon large color="#B4B4B4">fas fa-user-circle</v-icon>
        </v-col>
        <v-col cols="9">
          <p class="fontsize18 font-weight-bold ma-0">ゲスト<span class="fontsize13">さん</span></p>
          <p class="fontsize11 mt-1 mb-0">会員ID  00000000</p>
        </v-col>
        <v-col cols="1">
          <v-icon x-small color="#fff">fas fa-chevron-right</v-icon>
        </v-col>
      </v-row>

      <v-divider class="my-3" color="#B4B4B4"></v-divider>

      <div class="submenuin" v-for="(submenu, i) in menus.sub_menus" :key="i">
        <v-row align="center" class="px-4">
          <v-col cols="11">
            <p class="ma-0 pl-2 body-2 font-weihgt-bold" >{{submenu.title}}</p>
          </v-col>
          <v-col cols="1">
            <v-icon x-small color="#fff">fas fa-chevron-right</v-icon>
          </v-col>
        </v-row>

        <v-divider class="my-3" color="#B4B4B4"></v-divider>
      </div>

      <div class="submenuin">
        <v-row align="center" class="px-4">
          <v-col cols="11">
            <p class="ma-0 pl-2 body-2 font-weihgt-bold">利用規約</p>
          </v-col>
          <v-col cols="1">
            <v-icon x-small color="#fff">fas fa-chevron-right</v-icon>
          </v-col>
        </v-row>

        <v-divider class="my-3" color="#B4B4B4"></v-divider>
      </div>

      <div class="submenuin">
        <v-row align="center" class="px-4">
          <v-col cols="11">
            <p class="ma-0 pl-2 body-2 font-weihgt-bold">利用設定</p>
          </v-col>
          <v-col cols="1">
            <v-icon x-small color="#fff">fas fa-chevron-right</v-icon>
          </v-col>
        </v-row>

        <v-divider class="my-3" color="#B4B4B4"></v-divider>
      </div>

    </div>
  </v-footer>
</template>

<script>
export default {
  props: ['menus'],
  data() {
    return {
      submenu_flg: false,
    };
  },

  created() {
  },
  methods: {
    submenu() {
      this.submenu_flg = !this.submenu_flg
    }

  },
  filters:{
    gfTitle(value){
      if(value.length > 6){
        value = value.substring(0,5) + "...";
      }
      return value;
    }
  }
}
</script>

<style scoped>
.gfoot {
  background-color: #595757;
  line-height: 1.1;
  max-height: 54px;
  box-sizing: border-box;
  z-index: 20 !important;
}
.submenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 54px);
  background-color: #707070;
  color: #fff;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 20 !important;
}
.gftext {
  font-size: 9px;
  color: #9D9D9D;
}
</style>